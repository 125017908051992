import React from "react";
import ARTIFEX from "../assets/ARTIFEX@2x.png";
import Footer from "./Footer.js";

export default () => {
  return (
    <div>
      <div id="navbar">
        <div
          id="navicon"
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              gap: "2%",
              width: "100%",
            }}
          >
            <a href="/" style={{ cursor: "pointer" }}>
              <div>
                <img
                  src={ARTIFEX}
                  alt="ARTIFEX"
                  width={"182px"}
                  height={"auto"}
                  className="artifex"
                />
              </div>
            </a>
          </div>
        </div>
      </div>

      <div style={{ color: "white", margin: "0px 5%" }}>
        <div>
          <h1 style={{ fontSize: "2rem" }}>Terms & Conditions </h1>
        </div>
        <h6 style={{ fontSize: "1rem" }}>Last updated on Jan 22nd 2024</h6>
        <div style={{ lineHeight: "1.5", textAlign: "justify" }}>
          {/* <p>
            The following terms and conditions will be deemed to have been
            accepted by the User on usage of the website "
            <b>https://artifexarena.com/</b>". You are requested to read them
            carefully before you use the services of this site.{" "}
          </p>
          <p>
            The term User shall refer to the user who is browsing the site. 
         
            The term Site refers to <b>"https://artifexarena.com/" </b>owned and
            monitored by   <b> Purplegene  </b> .{" "}
          </p> */}

          <p>
            Artifexarena.com(hereafter referred to as "Website") is an online
            platform which is owned and monitored by Purplegene. The term User
            shall refer to the user who is browsing the site.
          </p>

          <p>
            The following terms and conditions will be deemed to have been
            accepted by the User on usage of the Website. You are requested to
            read them carefully before you use the services of this site.
          </p>
          <ul>
            <li>
              By using the Site, you agree to follow and be bound by the
              following terms and conditions concerning your use of the Site.{" "}
              <b> Purplegene</b> may revise the Terms of Use at any time
              without notice to you. Areas of the Site may have different terms
              of use posted. If there is a conflict between the Terms of Use and
              terms of use posted for a specific area of the Site, the latter
              shall have precedence with respect to your use of that area of the
              Site.{" "}
            </li>

            <li>
              <b> Purplegene </b>
              may terminate User's access at any time for any reason. The
              provisions regarding to disclaimer of warranty, accuracy of
              information, and indemnification shall survive such termination.{" "}
              <b> Purplegene </b>
              may monitor access to the Site.{" "}
            </li>

            <li>
              All content present on this site is the exclusive property of{" "}
              <b> Purplegene </b>
              for artifex web. The software, text, images, graphics, video and
              audio used on this site belong to <b> Purplegene </b> . No
              material from this site may be copied, modified, reproduced,
              republished, uploaded, transmitted, posted or distributed in any
              form without prior written permission from <b> Purplegene </b> .
              All rights not expressly granted herein are reserved. Unauthorized
              use of the materials appearing on this site may violate copyright,
              trademark and other applicable laws, and could result in criminal
              or civil penalties.
              {/*  Purplegeneis a registered trademark of{" "}
              <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b> Limited. This trademark
              may not be used in any manner without prior written consent from{" "}
              <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b> Limited.{" "}*/}
            </li>

            <li>
              <b> Purplegene </b>
              does not make any warranties, express or implied, including,
              without limitation, those of merchantability and fitness for a
              particular purpose, with respect to any information, data,
              statements or products made available on the Site.
            </li>

            <li>
              The Site, and all content, materials, information, software,
              products and services provided on the Site, are provided on an "as
              is" and "as available" basis. <b> Purplegene </b>
              expressly disclaims all warranties of any kind, whether express or
              implied, including, but not limited to, the implied warranties of
              merchantability, fitness for a particular purpose and
              non-infringement.{" "}
            </li>

            <li>
              <b> Purplegene </b>
              shall have no responsibility for any damage to User's computer
              system or loss of data that results from the download of any
              content, materials, information from the Site.{" "}
            </li>

            <li>
              <b> Purplegene </b>
              may change or discontinue any aspect of its website at any time,
              including, its content or features. <b> Purplegene </b>
              reserves the right to change the terms and conditions applicable
              to use of the Site. Such changes shall be effective immediately
              upon notice, which shall be placed on the Site.{" "}
            </li>

            <li>
              In no event will Purplegenebe liable for damages of any kind,
              including without limitation, direct, incidental or consequential
              damages (including, but not limited to, damages for lost profits,
              business interruption and loss of programs or information) arising
              out of the use of or inability to use{" "}
              {/* <b>PRAGMATIQ SYSTEMS PRIVATE LIMITED</b>'s through  */}
              Purplegene app, or any information provided on the website, or in
              the Products any claim attributable to errors, omissions or other
              inaccuracies in the Product or interpretations thereof. Some
              jurisdictions do not allow the limitation or exclusion of
              liability. Accordingly, some of the above limitations may not
              apply to the User.{" "}
            </li>

            <li>
              User agrees to indemnify, defend and hold <b> Purplegene </b>
              harmless from and against all losses, expenses, damages and costs,
              including reasonable attorneys' fees, arising out of or relating
              to any misuse by the User of the content and services provided on
              the Site.{" "}
            </li>

            <li>
              The information contained in the Site has been obtained from
              sources believed to be reliable. <b> Purplegene </b>
              disclaims all warranties as to the accuracy, completeness or
              adequacy of such information.
            </li>

            <li>
              <b> Purplegene </b>
              makes no warranty that: (a) the Site will meet your requirements;
              (b) the Site will be available on an uninterrupted, timely,
              secure, or error-free basis; (c) the results that may be obtained
              from the use of the Site or any services offered through the Site
              will be accurate or reliable.{" "}
            </li>

            <li>
              The User's right to privacy is of paramount importance to{" "}
              <b> Purplegene </b>
              through artifex website. Any information provided by the User will
              not be shared with any third party.{" "}
              <b>Purplegene</b> reserves the right to use
              the information to provide the User a more personalized online
              experience.{" "}
            </li>

            <li>
              The Site provides links to web sites and access to content,
              products and services from third parties, including users,
              advertisers, affiliates and sponsors of the Site. You agree that{" "}
              <b> Purplegene </b> is not responsible for the availability of,
              and content provided on, third party web sites. The User is
              requested to peruse the policies posted by other web sites
              regarding privacy and other topics before use. Purplegeneis not
              responsible for third party content accessible through the App,
              including opinions, advice, statements and advertisements, and
              User shall bear all risks associated with the use of such content.
              Purplegeneis not responsible for any loss or damage of any sort
              User may incur from dealing with any third party.{" "}
            </li>

            <li>
              {" "}
              For the purpose of these Terms and Conditions, The term "we",
              "us", "our" used anywhere on this page shall mean{" "}
              <b> Purplegene </b>
              under arftifex website, whose registered/operational office is
              D401, Vajra Sreenivasam, Raghavendra Colony, Kondapur Hyderabad
              TELANGANA 500084 . "you", "your", "user", "visitor" shall mean any
              natural or legal person who is visiting our website and/or agreed
              to purchase from us.
            </li>
          </ul>
          <p>
            Your use of the website and/or purchase from us are governed by
            following Terms and Conditions:
          </p>
          <ul>
            <li>
              The content of the pages of this website is subject to change
              without notice. Neither we nor any third parties provide any
              warranty or guarantee as to the accuracy, timeliness, performance,
              completeness or suitability of the information and materials found
              or offered on this website for any particular purpose. You
              acknowledge that such information and materials may contain
              inaccuracies or errors and we expressly exclude liability for any
              such inaccuracies or errors to the fullest extent permitted by
              law.
            </li>
            <li>
              {" "}
              Your use of any information or materials on our website and/or
              product pages is entirely at your own risk, for which we shall not
              be liable. It shall be your own responsibility to ensure that any
              products, services or information available through our website
              and/or product pages meet your specific requirements.
            </li>
            <li>
              {" "}
              Our website contains material which is owned by or licensed to us.
              This material includes, but are not limited to, the design,
              layout, look, appearance and graphics. Reproduction is prohibited
              other than in accordance with the copyright notice, which forms
              part of these terms and conditions.
            </li>
            <li>
              {" "}
              All trademarks reproduced in our website which are not the
              property of, or licensed to, the operator are acknowledged on the
              website.
            </li>
            <li>
              Unauthorized use of information provided by us shall give rise to
              a claim for damages and/or be a criminal offense.
            </li>
            <li>
              {" "}
              From time to time our website may also include links to other
              websites. These links are provided for your convenience to provide
              further information.
            </li>
            <li>
              {" "}
         
              You may not create a link to our website from another website or document without the prior written consent of <b> Purplegene </b>
            </li>
            <li>
              Any dispute arising out of use of our website and/or purchase with
              us and/or any engagement with us is subject to the laws of India .
            </li>
            <li>
              {" "}
              We, shall be under no liability whatsoever in respect of any loss
              or damage arising directly or indirectly out of the decline of
              authorization for any Transaction, on Account of the Cardholder
              having exceeded the preset limit mutually agreed by us with our
              acquiring bank from time to time.
            </li>
          </ul>
        </div>
      </div>
      <Footer />
    </div>
  );
};
