// VideoPlayer.js
import React, { useState } from 'react';
import YouTube from 'react-youtube';

const VideoPlayer = ({ videoId }) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const opts = {
    height: '300',
    width: '500',
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: isPlaying ? 1 : 0,
    },
  };

  return (
    <div>
      <YouTube videoId={videoId} opts={opts} id='video'/>
      {/* <button onClick={() => setIsPlaying(!isPlaying)}>
        {isPlaying ? 'Pause' : 'Play'}
      </button> */}
    </div>
  );
};

export default VideoPlayer;
