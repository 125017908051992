import React, { useState, useEffect } from "react";
import Axios from "axios";
import "./Register.css";
import Footer from "./Footer.js";
import Config from "../Config.jsx";
import ARTIFEX from "../assets/ARTIFEX@2x.png";
import RVR_JC_logo from "../assets/RVR_JC_logo.png";
import ReactDOM from "react-dom";
import ReCAPTCHA from "react-google-recaptcha";

const Register = () => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [isRecaptchaVerified, setRecaptchaVerified] = useState(false);

  useEffect(() => {
    // Update current time every second
    const intervalId = setInterval(() => {
      setCurrentTime(new Date());
    }, 1000);

    // Clear interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const targetTime = new Date();
  targetTime.setHours(17, 0, 0); // Set the target time to 5 PM

  // Compare the current time with 5 PM

  const [formData, setFormData] = useState({
    name: "",
    mobileNumber: "",
    email: "",
    collegeName: "",
    //date and time
    // createdDate: new Date().toLocaleString('en-IN', {
    //   year: 'numeric',
    //   month: '2-digit',
    //   day: '2-digit',
    //   hour: '2-digit',
    //   minute: '2-digit',
    //   second: '2-digit',

    // }),
    // createdDate: new Date().toLocaleDateString(),
  });

  const [fieldValidity, setFieldValidity] = useState({
    name: true,
    mobileNumber: true,
    email: true,
    collegeName: true,
  });
  const handleChange = (event) => {
    const { name, value } = event.target;

    // Update form data
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    // Validate the field
    let isValid;

    if (name === "email") {
      // Check if the email follows the specified pattern
      isValid =
        /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value) &&
        /@[^\s@]+\.[a-z]+$/.test(value);
    } else if (name === "mobileNumber") {
      // Validate mobile number
      isValid = /^\d+$/.test(value) && /^[6-9]\d{9}$/.test(value);
    } else {
      // Add additional validation logic for other fields if needed
      isValid = true;
    }

    // Update field validity status
    setFieldValidity((prevFieldValidity) => ({
      ...prevFieldValidity,
      [name]: isValid,
    }));
  };

  console.log("button value", isRecaptchaVerified);
  const handleRecaptchaChange = (token) => {
    setRecaptchaToken(token);
    setRecaptchaVerified(true); // Set to true when reCAPTCHA is completed
  };
  // const handleSubmit = async (e) => {
  //   e.preventDefault();

  //   // Check for empty fields
  //   const newFieldValidity = {};
  //   for (const key in formData) {
  //     if (formData[key].trim() === "") {
  //       newFieldValidity[key] = false;
  //     } else {
  //       newFieldValidity[key] = true;
  //     }
  //   }

  //   // Additional checks for email and mobile number
  //   const isMobileNumberValid = /^[6-9]\d{9}$/.test(formData.mobileNumber);
  //   const isEmailValid = /^[a-zA-Z0-9._-]+@gmail\.com$/.test(formData.email);

  //   // Update field validity state
  //   setFieldValidity({
  //     ...newFieldValidity,
  //     mobileNumber: isMobileNumberValid,
  //     email: isEmailValid,
  //   });

  //   // If any field is empty or validation fails, prevent form submission
  //   if (
  //     Object.values(newFieldValidity).some((valid) => !valid) ||
  //     !isMobileNumberValid ||
  //     !isEmailValid
  //   ) {
  //     console.error("Please fill in all required fields with valid data");
  //     return;
  //   } else {
  //     console.log(formData);
  //     if (isRecaptchaVerified) {
  //       // If verification is successful, proceed with form submission
  //       // ...
  //     } else {
  //       // Handle the case where reCAPTCHA verification fails
  //       console.error("Please complete the reCAPTCHA challenge");
  //     }
  //     try {
  //       const response = await fetch(`${Config.base_url}/userDatadb`, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/x-www-form-urlencoded",
  //         },
  //         body: new URLSearchParams(formData).toString(),
  //       });
  //       // const recaptchaValue = await verifyRecaptcha();
  //       if (response.ok) {
  //         const result = await response.json();
  //         console.log(result);

  //         try {
  //           const response = await fetch(`${Config.base_url}/send-whatsapp`, {
  //             method: "POST",
  //             headers: {
  //               "Content-Type": "application/x-www-form-urlencoded",
  //             },
  //             body: new URLSearchParams({
  //               mobileNumber: formData.mobileNumber,
  //             }).toString(),
  //           });

  //           if (response.ok) {
  //             const result = await response.json();
  //             console.log(result);
  //           } else {
  //             console.error("Error in sending WhatsApp message");
  //           }
  //         } catch (error) {
  //           console.error("Error:", error);
  //         }
  //         alert("Registration Successful");
  //         window.location.href = "./";
  //       } else if (response.status === 400) {
  //         alert("Mobile number already exists");
  //       } else {
  //         console.error("Error in form submission");
  //       }
  //     } catch (error) {
  //       console.error("Error:", error);
  //     }
  //   }
  // };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Check for empty fields
    const newFieldValidity = {};
    for (const key in formData) {
      if (formData[key].trim() === "") {
        newFieldValidity[key] = false;
      } else {
        newFieldValidity[key] = true;
      }
    }

    // Additional checks for email and mobile number
    const isMobileNumberValid = /^[6-9]\d{9}$/.test(formData.mobileNumber);
    const isEmailValid = /^[a-zA-Z0-9._-]+@gmail\.com$/.test(formData.email);

    // Update field validity state
    setFieldValidity({
      ...newFieldValidity,
      mobileNumber: isMobileNumberValid,
      email: isEmailValid,
    });

    // If any field is empty or validation fails, prevent form submission
    if (
      Object.values(newFieldValidity).some((valid) => !valid) ||
      !isMobileNumberValid ||
      !isEmailValid
    ) {
      console.error("Please fill in all required fields with valid data");
      alert("Please fill in all required fields with valid data");
      return;
    } else {
      console.log(formData);
      if (isRecaptchaVerified) {
        // If verification is successful, proceed with form submission
        // ...
      } else {
        // Handle the case where reCAPTCHA verification fails
        console.error("Please complete the reCAPTCHA challenge");
      }
    }

    // If reCAPTCHA verification fails, prevent form submission
    if (!isRecaptchaVerified) {
      console.error("Please complete the reCAPTCHA challenge");
      alert("Please complete the reCAPTCHA challenge");
      return;
    }

    // Proceed with form submission
    try {
      const res = await Axios.post(`${Config.base_url}/registerUser`, formData);
      console.log("res", res);
      if (res.data.message === "User already exists") {
        alert("Mobile number already exists");
      } else if (res.data.message === "User Added Successfully") {
        // try {
        //   const whatsappResponse = await fetch(
        //     `${Config.base_url}/send-whatsapp`,
        //     {
        //       method: "POST",
        //       headers: {
        //         "Content-Type": "application/x-www-form-urlencoded",
        //       },
        //       body: new URLSearchParams({
        //         mobileNumber: formData.mobileNumber,
        //       }).toString(),
        //     }
        //   );

        //   if (whatsappResponse.ok) {
        //     const whatsappResult = await whatsappResponse.json();
        //     console.log(whatsappResult);
        //   } else {
        //     console.error("Error in sending WhatsApp message");
        //   }
        // } catch (whatsappError) {
        //   console.error("Error in sending WhatsApp message:", whatsappError);
        // }
        alert("Registration Successful");
        window.location.href = "./";
      } else {
        console.error("Error in form submission");
        alert("Error in the form submission.Please try again");
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <div id="Now">
      {" "}
      <div id="navbar">
        <div
          id="navicon"
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              gap: "2%",
              width: "100%",
            }}
          >
            <a href="/" style={{ cursor: "pointer" }}>
              <div>
                <img
                  src={ARTIFEX}
                  alt="ARTIFEX"
                  width={"182px"}
                  height={"auto"}
                  className="artifex"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div style={{ width: "100%", textAlign: "center" }}>
        <h1 style={{ marginTop: "0%", color: "rgb(239, 195, 106)" }}>
          Step into a world of opportunities – get started with us
        </h1>
      </div>
      <div id="loginpage">
        <form onSubmit={handleSubmit}>
          <h1>
            {" "}
            <img src={RVR_JC_logo} width={50} /> ARTIFEX SPRING '24
          </h1>
          <div id="title" style={{ margin: "1%" }}>
            {/* <p style={{ color: "white", margin: "0px" }}>
                In Associated With
              </p> */}
            <p
              style={{
                color: "white",
                margin: "0px",
                fontSize: "12px",
                textAlign: "center",
              }}
            >
              In Association With{" "}
              <span
                style={{ color: "#efc36a", margin: "0px", fontSize: "17px" }}
              >
                R.V.R & J.C COLLEGE OF ENGINEERING. GUNTUR.A.P
              </span>
            </p>
          </div>
          <h2>Please enter the details to Register yourself</h2>
          <div className="form">
            <label>
              Name *
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                placeholder="Please Enter Name"
                className={fieldValidity.name ? "" : "error"}
              />
              {!fieldValidity.name && (
                <span className="error-message">Name is required</span>
              )}
            </label>

            <label>
              Mobile Number *
              <input
                type="text"
                name="mobileNumber"
                value={formData.mobileNumber}
                onChange={handleChange}
                placeholder="Please Enter Mobile Number"
                className={fieldValidity.mobileNumber ? "" : "error"}
              />
              {!fieldValidity.mobileNumber && (
                <span className="error-message">enter valid number </span>
              )}
            </label>

            <label>
              Email *
              <input
                type="text"
                name="email"
                value={formData.email}
                onChange={handleChange}
                placeholder="Please Enter Email Id"
                className={fieldValidity.email ? "" : "error"}
              />
              {!fieldValidity.email && (
                <span className="error-message">Email is required</span>
              )}
            </label>

            <label>
              College Name *
              <input
                type="text"
                name="collegeName"
                value={formData.collegeName}
                onChange={handleChange}
                placeholder="Please Enter College Name"
                className={fieldValidity.collegeName ? "" : "error"}
              />
              {!fieldValidity.collegeName && (
                <span className="error-message">College Name is required</span>
              )}
            </label>
          </div>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "6%",
              // gap: "22%",
              // justifyContent: "center",
            }}
          >
            <ReCAPTCHA
              sitekey={`${Config.siteKey}`} //to change this check the config.jsx file
              onChange={handleRecaptchaChange}
            />
          </div>
          <div
            style={{ display: "flex", justifyContent: "center", padding: "2%" }}
          >
            {isRecaptchaVerified ? <button type="submit">Submit</button> : ""}
          </div> */}

          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              marginTop: "6%",
              padding: "0 10%", // Add padding to both sides
            }}
          >
            <div style={{ maxWidth: "300px", width: "100%" }}>
              {" "}
              {/* Set maximum width */}
              <ReCAPTCHA
                sitekey={`${Config.siteKey}`} //to change this check the config.jsx file
                onChange={handleRecaptchaChange}
                className="custom-recaptcha"
                // size="compact" // optional: adjust the size of the ReCAPTCHA for smaller screens
                style={{ marginBottom: "10px" }} // Add margin bottom for spacing
              />
            </div>
            <div style={{ width: "100%", display: "contents" }}>
              {" "}
              {isRecaptchaVerified ? (
                <button
                  style={{
                    display: "flex",
                    justifyContent: "center",
                  }}
                  type="submit"
                >
                  Submit
                </button>
              ) : (
                ""
              )}
            </div>
          </div>
        </form>
      </div>
      <div style={{ marginTop: "5%" }}>
        <Footer />
      </div>
    </div>
  );
};

export default Register;
