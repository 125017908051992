import React, { useEffect, useState } from "react";
import { HashLink } from "react-router-hash-link";
import { Link as ScrollLink, animateScroll as scroll } from 'react-scroll';
import Footer from "./Footer.js";
import "./Home.css";
import icon_2 from "../assets/icon_new.png";
import ARTIFEX from "../assets/ARTIFEX@2x.png";
import arrowforward from "../assets/arrow-forward.png";
import arrow_f_w from "../assets/arrow_f_w.png";
import QR_code from "../assets/QR_code.png";
import arrow3Down from "../assets/arrow3Down.png";
import imagescroll_1 from "../assets/imagescroll_1.png";
import imagescroll_2 from "../assets/imagescroll_2.png";
import imagescroll_3 from "../assets/imagescroll_3.png";
//slider define
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Axios from "axios";
// import card1 from "../assets/card1.png";
// import card2 from "../assets/card2.png";
// import card3 from "../assets/card3.png";
import appstore from "../assets/appstore.png";
import playstore from "../assets/playstore.png";
// import handwithQR from "../assets/handwithQR.png";
import footerimg from "../assets/footerimg.png";
// import BgImg from "../assets/MicrosoftTeams-image (6).png";
import ArrowDown from "../assets/arrow-down.png";
import arrowForW from "../assets/arrow-for-w.png";
//footer images
import { Link } from "react-router-dom";
import call from "../assets/call.png";
import mail from "../assets/mail.png";
import whasapp from "../assets/whasapp.png";
import ImageSlider from "./test";
import VideoPlayer from "./VideoPlayer.js";
import Config from "../Config.jsx";
export default function Home() {
  //timer

  const [timeRemaining, setTimeRemaining] = useState(calculateTimeRemaining());
  const [Heroimages, setHeroimages] = useState([]);

  const [Landingpagedata, setLandingpagedata] = useState([]);
  useEffect(() => {
    const intervalId = setInterval(() => {
      setTimeRemaining(calculateTimeRemaining());
    }, 1000);

    // Cleanup the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  function calculateTimeRemaining() {
    const countDownDate = new Date("Jan 22, 2024 17:00:00").getTime();
    const now = new Date().getTime();
    const distance = countDownDate - now;

    const days = Math.floor(distance / (1000 * 60 * 60 * 24));
    const hours = Math.floor(
      (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
    );
    const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((distance % (1000 * 60)) / 1000);

    return {
      days,
      hours,
      minutes,
      seconds,
    };
  }

  //button scroll
  const scrollToTop = () => {
    scroll.scrollToTop();
  };
//
  useEffect(() => {
    async function Heroimages() {
      try {
        await Axios.get(`${Config.base_url}/heroImages`).then((response) => {
          // console.log(response.data, "____________");
          setHeroimages(response.data);
        });
      } catch (error) {
        console.error(
          "There was a problem fetching the image list:",
          error.message
        );
      }
    }
    async function Landingpagedata() {
      try {
        await Axios.get(`${Config.base_url}/landingPageData`).then(
          (response) => {
            // console.log(response.data, "____________");
            setLandingpagedata(response.data);
          }
        );
      } catch (error) {
        console.error(
          "There was a problem fetching the image list:",
          error.message
        );
      }
    }
    Heroimages();
    Landingpagedata();
  }, []);
  // console.log("mydata", Landingpagedata[0]?.section2.images);
  const settings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    infinite: true,
    speed: 500,
    dots: false,
    arrows: false,
    centerMode: true,
    centerPadding: "0",
  };

  const CardsList = () => {
    return (
      <div className="card-list">
        {Landingpagedata[0]?.section5.items.map((item, inbox) =>
          item.title ? (
            <div className="card">
              <h2>{item.title}</h2>
              <p>{item.description}</p>
              {/* <img src={Data["image"]} /> */}
              <img src={`${Config.imageUrl}/${item.image}`} />
            </div>
          ) : null
        )}
      </div>
    );
  };

  // const Eventitems = [imagescroll_1, imagescroll_2, imagescroll_3];
  const settingsimag = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1, // 1 display at a time
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: false,
  };

  // const youtubeVideoId = ["sl8a3StTumU", "sl8a3StTumU", "sl8a3StTumU"];
  return (
    <div>
      <div className="MainContainer">
        <div class="light"></div>

        <div id="BgImg">
          <div id="imageslider">
            <ImageSlider />
            <ImageSlider modifiedAnimation />
            <ImageSlider />
            <ImageSlider modifiedAnimation />
            <ImageSlider />
            <ImageSlider modifiedAnimation />
          </div>
        </div>
        <div id="Container">
          {/* <div id="first">
                <img
                  src={icon_2}
                  alt="icon"
                  width="100px"
                  height="100px"
                  id="icon"
                />
                <div>
                  <img
                    src={ARTIFEX}
                    alt="ARTIFEX"
                    width="100px"
                    height="100px"
                    id="ArtifexImg"
                  />
                  <div style={{ textAlign: "center" }}>
                    <p id="ArtifexCon">Unleash the X-Factor in you</p>
                    {/* <div>
                      <Link to="/Spring" style={{ textDecoration: "none" }}>
                        <p id="GetStart">{Data["eventTitle"]}</p>
                      </Link>
                    </div> */}
          {/* </div>
                  <HashLink to="/#navbar">
                    <div id="Arrow"> */}
          {/* <div class="arrow-container"> */}
          {/* <div class="arrow-down">
                        <img
                          src={arrow3Down}
                          alt="arrow"
                          width={70}
                          height={100}
                        />
                      </div> */}
          {/* </div> */}
          {/* </div>
                  </HashLink>
                </div> */}
          {/* <div
            style={{
              position: "fixed",
              zIndex: "1",
              bottom: "0", 
              right: "0", 
              margin: "30px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <img src={QR_code} width={"20%"} height={"auto"} />
          </div> */}
          {/* </div>  */}
          <div className="imagescroll">
            <div className="carousel-container carousel-container-1">
              <Slider
                {...settingsimag}
                autoplay
                pauseOnHover={false}
                dots={false}
                arrows={false}
              >
                {Heroimages.map((image, index) => (
                  <img
                    key={index}
                    src={`${Config.imageUrl}/${image.imageurl}`}
                    alt={`Image ${index}`}
                    style={{
                      border: "3px solid #efc36a",
                      borderRadius: "30px",
                      padding: "6px",
                    }}
                  />
                ))}
              </Slider>
            </div>
            {/* <ScrollLink to="#navbar" style={{ marginTop: "5%" }} smooth={true} duration={500}> */}
            <HashLink to="#navbar" style={{ marginTop: "5%" }}  smooth={true} duration={500}>
              <div id="Arrow">
                {/* <div class="arrow-container"> */}
                <div className="arrow-down">
                  <img src={arrow3Down} alt="arrow" className="arrow-downimg" />
                </div>
                {/* </div> */}
              </div>
            </HashLink>
            {/* </ScrollLink> */}
          </div>

          <div
            style={{
              position: "fixed",
              zIndex: "1",
              bottom: "0",
              right: "0",
              margin: "30px",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            {/* <div id="timer">
              <div id="days">{timeRemaining.days} days</div>
              <div id="hours">{timeRemaining.hours} hours</div>
              <div id="minutes">{timeRemaining.minutes} minutes</div>
              <div id="seconds">{timeRemaining.seconds} seconds</div>
            </div> */}
          </div>
          <div id="navbar">
            <div
              id="navicon"
              style={{
                display: "flex",
                alignItems: "center",
                // width: "50%",
              }}
            >
              {/* <div>
                    <img
                      src={icon_2}
                      alt="icon"
                      width={"58px"}
                      height={"auto"}
                      className="icon"
                    />
                  </div> */}
              <div>
                <img
                  src={ARTIFEX}
                  alt="ARTIFEX"
                  width={"182px"}
                  height={"auto"}
                  className="artifex"
                />
                <p></p>
              </div>
            </div>
            <p id="landing">SPRING '24 REGISTRATIONS ARE OPEN NOW</p>
            <div>
              <HashLink
                to="/Register#Now"
                style={{ textDecoration: "none", width: "50%" }}
              >
                <div id="downloadbutton">
                  <div>
                    <p>Register Now</p>
                  </div>

                  {/* <button onClick={handleOpen}>Download App</button> */}

                  <div>
                    <img src={arrowforward} />
                  </div>
                </div>
              </HashLink>
            </div>
            {/* {open && (
            <div className="modal">
              <div className="modal-content">
             
                <div id="modal-box1">
                  <div style={{ width: "60%" }} id="modal-inbox">
                    <div>
                      <h2>Download Purplegene App</h2>

                      <p style={{ marginBottom: "6px" }}>
                        Enter your Whatsapp number & we'll send you a download
                        link.
                      </p>
                      <div id="modal-box1-1">
                        <div style={{ width: "100%" }}>
                          <input
                            type="text"
                            placeholder="Enter WhatsApp Number"
                          />
                        </div>
                        <div>
                          <button
                            type="submit"
                            style={{
                              backgroundColor: "transparent",
                              border: "none",
                            }}
                          >
                            <img
                              src={arrowForW}
                              alt="Send"
                              width={30}
                              style={{
                                backgroundColor: "transparent",
                              }}
                            />
                          </button>
                        </div>
                      </div>
                      <p>Download the Purplegene app to get amazing features</p>
                    </div>
                    <div className="modal-box2">
                      <span className="close" onClick={handleClose}>
                        Close
                      </span>
                      {/* x symbel  &times; */}
            {/* </div>
                  </div>
                  <div id="imgdiv">
                    <img src={handwithQR} id="image" />
                  </div>
                </div>
               
              </div>
            </div>
          )} */}
          </div>

          <h1 className="mainheading">ARTIFEX ARENA</h1>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "68px 0px ",
              padding: "0px 5% ",
            }}
          >
            <div id="ContentBox">
              <p
                style={{
                  fontSize: "30px",
                  lineHeight: "3rem",
                  letterSpacing: "1px",
                  wordSpacing: "1px",
                }} id="p"
              >
                {/* {Data["eventDescription"]} */}
                {Landingpagedata[0]?.section1.description}
              </p>
            </div>
          </div>

          <h1 className="heading">{Landingpagedata[0]?.section2.heading}</h1>
          <h1 className="subheading">
            {Landingpagedata[0]?.section2.subheading}
          </h1>
          <div
            style={{
              height: "60vh",
              overflow: "hidden",
              margin: "60px 4%",
              width: "96%",
            }}
            className="carousel-container carousel-container-1"
            id="section2"
          >
            {/* <div
    className="scrolling-wrapper"
    style={{
      display: "flex",
      animation: "scroll 20s linear infinite", 
    }}
  >
    {repeatListviews.map((Listview, index) => (
      <HashLink
        key={index}
        to={`/Sustainability#section${index}`}
      >
        <img
          key={index}
          src={Listview}
          alt={`Image ${index}`}
          style={{
            width: "100%",
            height: "40vh",
            marginRight: "1%",
            border: "3px solid #efc36a",
            borderRadius: "30px",
            padding: "6px",
            marginLeft: "42px",
          }}
          className="slide horizontal-slide"
        />
      </HashLink>
    ))}
  </div> */}

            <Slider
              {...settings}
              autoplay
              autoplaySpeed={1000}
              pauseOnHover={false}
            >
              {Landingpagedata[0]?.section2.images.map((image, index) => (
                <HashLink key={index} to={`/Sustainability#section${index}`}>
                  <img
                    key={index}
                    src={`${Config.imageUrl}/${image}`}
                    alt={`Image ${index}`}
                    style={{
                      // width: "100%",
                      // height: "40vh",
                      // marginRight: "1%",
                      border: "3px solid #efc36a",
                      borderRadius: "30px",
                      padding: "6px",
                      // marginLeft: "42px",
                    }}
                    className="slide_horizontal_slide"
                  />
                </HashLink>
                // ):null
              ))}
            </Slider>
          </div>
          <h1 className="heading">{Landingpagedata[0]?.section3.heading}</h1>
          <h1 className="subheading">
            {Landingpagedata[0]?.section3.subheading}
          </h1>
          <div className="carousel-container">
            <div className="carousel-container carousel-container-1">
              <Slider
                {...settings}
                autoplay
                autoplaySpeed={3000}
                pauseOnHover={false}
              >
                {Landingpagedata[0]?.section3.images.map((image, index) =>
                  image != null ? (
                    <HashLink
                      key={index}
                      // to={`/Spring#section${index}`}
                      style={{ textDecoration: "none" }}
                    >
                      <div key={index} className="image-container">
                        <img
                          src={`${Config.imageUrl}/${image.image}`}
                          alt={image}
                        />
                      </div>
                    </HashLink>
                  ) : null
                )}
              </Slider>
            </div>
          </div>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              marginBottom: "5%",
            }}
          >
            <a href="/ArtifexData" className="AQ">
              <div>
                <h1 style={{ fontFamily: "Montserrat, SemiBold" }}>
                  Hey Whats your "AQ"
                </h1>
                <p>Know your AQ and achieve your life</p>
              </div>

              <button>
                <img src={arrow_f_w} alt="image" width={20} height={20} />
              </button>
            </a>
          </div>
          <br />
          <br />

          {/* <div style={{textAlign:'center',width:'100%',marginBottom: "70px",marginTop: "20px",}}>
        <a href="/Gole" style={{color:'white'}} >Know more</a>
        </div> */}

          <h1 className="heading">{Landingpagedata[0]?.section5.heading}</h1>
          <h1 className="subheading">
            {Landingpagedata[0]?.section5.subheading}
          </h1>
          <div>
            <CardsList />
          </div>
          {/* <div style={{ position: "relative", zIndex: 2 }}>
            {/* <div className="store">
              <a href={Landingpagedata[0]?.section7.appstoreurl}>
                <img src={appstore} width={"100%"} />
              </a>
              <a href={Landingpagedata[0]?.section7.playstoreurl}>
                <img src={playstore} width={"100%"} />
              </a>
            </div> 
            <img
              src={`${Config.imageUrl}/${Landingpagedata[0]?.section7.image}`}
              id="footerimg"
            />
          </div> */}
          <div
            style={{
              margin: "7% 0px",
            }}
          >
            <h1 className="heading">{Landingpagedata[0]?.section6.heading}</h1>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "7%",
                margin: " 5%",
                alignItems: "center",
                justifyContent: 'center',
              }}
            >
              {Landingpagedata[0]?.section6.videoid.map((id, index) => (
                <div
                  style={{
                    marginBottom: "3%",
                  }}
                >
                  <VideoPlayer videoId={id} />
                </div>
              ))}
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
}
