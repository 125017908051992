import React from "react";
import s_1 from "../images/s_1.png";
import s_2 from "../images/s_2.png";
import s_3 from "../images/s_3.png";
import s_4 from "../images/s_4.png";
import s_5 from "../images/s_5.png";
import s_6 from "../images/s_6.png";
import s_7 from "../images/s_7.png";
import s_8 from "../images/s_8.png";
import s_9 from "../images/s_9.png";
import s_10 from "../images/s_10.png";
import s_11 from "../images/s_11.png";
import s_12 from "../images/s_12.png";
import s_13 from "../images/s_13.png";
import s_14 from "../images/s_14.png";
import s_15 from "../images/s_15.png";
import s_16 from "../images/s_16.png";
import s_17 from "../images/s_17.png";

const ImageSlider = ({ modifiedAnimation }) => {
  const images = [
    s_1,
    s_2,
    s_3,
    s_4,
    s_5,
    s_6,
    s_7,
    s_8,
    s_9,
    s_10,
    s_11,
    s_12,
    s_13,
    s_14,
    s_15,
    s_16,
    s_17,
    // Add more image paths as needed
  ];

  // const repeatedImages = [];

  // // Repeat images infinitely
  // for (let i = 0; i < 10; i++) {
  //   repeatedImages.push(...images);
  // }

  return (
    <div className="image-slider">
      <div
        className={`slider-wrapper ${
          modifiedAnimation ? "slider-wrapper-modified" : ""
        }`}
      >
        {" "}
        {/* Add scroll-up class */}
        {images.map((image, index) => (
          <img
            key={index}
            src={image}
            alt={`Image ${index + 1}`}
            className="slide vertical-slide" // Add vertical-slide class
          />
        ))}
      </div>
    </div>
  );
};

/* 


#imageslider{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 4%;
  background-color: white;
}
//use this type in app

<div id="imageslider">
          <ImageSlider/>
          <ImageSlider modifiedAnimation/>
          <ImageSlider/>
          <ImageSlider modifiedAnimation/>
          <ImageSlider/>
          <ImageSlider modifiedAnimation/>
        </div>

//css for test page

.image-slider {
  height: 700px;
  overflow: hidden;
}
.slide {
  height: 100%;
  width: 100%;
  margin-bottom: 10%;
  border-radius: 50px;
}
.slider-wrapper {
  display: flex;
  flex-direction: column;

  animation: scrollImagesDown 5000s linear infinite;
}
.slider-wrapper-modified {

  animation: scrollImagesup 5000s linear infinite;
}

@keyframes scrollImagesup{
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

@keyframes scrollImagesDown {
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(0%);
  }
}

 */

export default ImageSlider;
