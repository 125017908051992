// Routes1.js
import React from "react";
import { Routes, Route } from "react-router-dom";
import Home from "./Pages/Home.js";
import Sustainability from "./Pages/Sustainability.js";
import ArtifexDetails from "./Pages/ArtifexDetails.js";
import Register from "./Pages/Register.js";
import Faqs from "./Pages/Faq's.js";
import TermsOfService from "./Pages/TermsOfService.js";

// import RefundPolicy from "./Pages/RefundPolicy.js";
import PrivacyPolicy from "./Pages/PrivacyPolicy.js";

// import Spring from './Pages/Spring.js';
export default function Routes1() {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="Sustainability" element={<Sustainability />} />
      <Route path="ArtifexData" element={<ArtifexDetails />} />
      <Route path="Register" element={<Register />} />
      <Route path="FooterPath/3" element={<Faqs />} />
      <Route path="FooterPath/6" element={<TermsOfService />} />
      {/* <Route path="RefundPolicy" element={<RefundPolicy />} /> */}
      <Route path="FooterPath/9" element={<PrivacyPolicy />}  /> 
      {/* <Route path="Spring" element={<Spring />} /> */}

       {/* <Route path="FooterPath/3" element={<TermsOfService />} />
      <Route path="FooterPath/6" element={<RefundPolicy />} />
      <Route path="FooterPath/9" element={<PrivacyPolicy />} /> */}
    </Routes>
  );
}
