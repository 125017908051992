// App.js or any other top-level component
import React from 'react';
import Routes1 from './Routes_1.js';

export default function App() {
  return (
    <div className="App">
      <Routes1 />
    </div>
  );
}
