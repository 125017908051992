// Prod/QA URLS
const base_url = "https://artifexarena.com";
const imageUrl = "https://artifexarena.com/static/images";
const siteKey = "6LeBxWgpAAAAAF2YOhal5Tdd7FCo50ywLm1q-iyr";

//create cpatch key using this site
// https://www.google.com/recaptcha/admin/create

// //LOCAL RUN
// const base_url = "http://localhost:6060";
// const imageUrl = "http://localhost:6060/images";
// const siteKey = "6LfwBGYpAAAAAPPrU7Hn-g67HRsXZdA7CdG73469";

const routes = {
  // listOfImages: "/images-list",
  images: "/heroImages",
  pagedata: "/landingPageData",
  sustainabilityData: "/sustainabilityData",
  artifexDetails: "/artifexDetails",
  userData: "/userData",
};

export default { routes, base_url, imageUrl, siteKey };
