import React, { useEffect, useState } from "react";
import Config from "../Config.jsx";
import Axios from "axios";
import { HashLink } from "react-router-hash-link";
import arrowforward from "../assets/arrow-forward.png";
import call from "../assets/call.png";

import mail from "../assets/mail.png";
import ARTIFEX from "../assets/ARTIFEX@2x.png";
import Footer from "./Footer.js";
export default function ArtifexDetails() {
  const [Data, setData] = useState([]);

  const fetchData = async () => {
    try {
      await Axios.get(`${Config.base_url}/artifexDetails`).then((response) => {
        console.log(response.data, "____________");
        setData(response.data);
      });
    } catch (error) {
      console.error(
        "There was a problem fetching the image list:",
        error.message
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div id="navbar" style={{ marginBottom: "3%" }}>
        <div
          id="navicon"
          style={{
            display: "flex",
            alignItems: "center",
            // width: "50%",
          }}
        >
          <div>
            <a href="/">
              <img
                src={ARTIFEX}
                alt="ARTIFEX"
                width={"182px"}
                height={"auto"}
                className="artifex"
              />
            </a>
          </div>
        </div>
        <p id="landing">SPRING '24 REGISTRATIONS ARE OPEN NOW</p>
        <div>
          <HashLink
            to="/Register#Now"
            style={{ textDecoration: "none", width: "50%" }}
          >
            <div id="downloadbutton">
              <div>
                <p>Register Now</p>
              </div>

              {/* <button onClick={handleOpen}>Download App</button> */}

              <div>
                <img src={arrowforward} />
              </div>
            </div>
          </HashLink>
        </div>
      </div>
      {Data.map((item, index) => (
        <div className="artifexcards" id={`#${item.id}`}>
          <p
            style={{
              fontSize: "50px",
              fontFamily: "Blanka, Regular",
              margin: "0px",
              color: item["color"],
              marginTop: "-6%",
              //   padding: 3% 0;
              width: "40%",
              padding: "2%",
            }}
            className="p"
            key={index}
          >
            <span
              style={{
                fontSize: "160px",
                margin: "0px",
                marginRight: "2%",
                textShadow: "#00000030 45px 9px 6px",
              }}
              key={index}
            >
              {item.heding}
            </span>
            {item.subheding}
          </p>
          <p
            style={{
              color: "white",
              fontSize: "20px",
              display: "flex",
              alignItems: "center",
              width:"65%",
            }}
          >
            {item.description}
          </p>
        </div>
      ))}
      <div>
        <Footer />
      </div>
    </div>
  );
}
