import React from "react";
import ARTIFEX from "../assets/ARTIFEX@2x.png";
import Footer from "./Footer.js";
export default () => {
  return (
    <div >
      <div id="navbar">
        <div
          id="navicon"
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              gap: "2%",
              width: "100%",
            }}
          >
            <a href="/" style={{ cursor: "pointer" }}>
              <div>
                <img
                  src={ARTIFEX}
                  alt="ARTIFEX"
                  width={"182px"}
                  height={"auto"}
                  className="artifex"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div style={{color:"white",margin:'0px 5%'}}>
        {/* <h1 style={{ fontSize: "2rem" }}>{headingText}</h1> */}
        <h6 style={{ fontSize: "1rem" }}>Last updated on Jan 22 2024</h6>
        <div style={{ textAlign: "justify" }}>
          <p
            style={{
              color: "Green",
              fontFamily: "cursive",
              fontSize: "1.5rem",
            }}
          >
            Your privacy is very important to us.
          </p>
          <p style={{ lineHeight: "1.5" }}>
            We have created this Privacy Policy to demonstrate our firm
            commitment to privacy and security. This Privacy Policy describes
            how our company collects information from all end users of our
            Internet services including those who access some of our Services
            but do not have accounts ("Visitors"). This Policy also explains
            what we do with the information we collect, and the choices Visitors
            have concerning the collection and use of such information. We
            request that you read this Privacy Policy carefully.
            <br />
            <p
              style={{
                color: "green",
                fontFamily: "cursive",
                fontSize: "1.5rem",
              }}
            >
              Personal information our company collects and how it is used
            </p>
            <p style={{  fontSize: "1.5rem",fontWeight:'bold' }}>Introduction</p>
            <p style={{ lineHeight: "1.5" }}>
              Visitors may be asked to provide certain personal information when
              they sign up for our Services including name, address, telephone
              number, billing information (such as a credit card number), and
              the type of personal computer being used to access the Services.
              The personal information collected from Members during the
              registration process (or at any other time) is used primarily to
              provide a customized experience as you use our Products and
              Services. Your information will never be licensed or sold to any
              third party. However, we may make limited disclosure of personal
              information under the specific circumstances described below.
            </p>
            <br />
            <ul>
              <li>keeping you informed about ongoing Learning programs.</li>

              <li>
                safeguarding your usernames, passwords, email addresses and
                other security-related information associated with our Services;
              </li>

              <li>
                delivering registration and subscription confirmations via SMS
                or other messaging services;
              </li>

              <li>
                providing updates or notifying you about changes related to your
                engagement;
              </li>
              <li>
                enabling our customer service to reach out to you if necessary;
              </li>
              <li>
                seeking your feedback on products, services, or enhancements
              </li>
              {/* <li>sending verification messages or emails.</li> */}
              <li>
                verifying and authenticating your account to prevent misuse or
                abuse
              </li>
              <li>
                your opinions and comments are valuable to us. We frequently
                conduct surveys, both online and offline. Participation in these
                surveys is optional. Collected information is usually aggregated
                and used to enhance the Website, other services, and create
                engaging content, features, and promotions for members, based on
                survey results. Participants' identities in surveys remain
                anonymous, unless explicitly stated otherwise
              </li>
            </ul>
          </p>
          <p style={{ fontWeight:'bold'}}>Responses to email inquiries</p>
          <p style={{ lineHeight: "1.5" }}>
            When Visitors send email inquiries to our company, the return email
            address is used to answer the email inquiry we receive. Our company
            does not use the return email address for any other purpose and does
            not share the return email address with any third party.
          </p>
          <br />

          {/* <p>
                You may also opt out of the DoubleClick cookie by visiting the
                Google advertising opt-out page:
              </p>
              <br />
              <a
                style={{ color: "blue" }}
                href="http://www.google.com/policies/privacy/ads/"
              >
                http://www.google.com/policies/privacy/ads/
              </a>
              <br />
              <p>
                Or you may opt out of Google Analytics by visiting the Google
                Analytics Opt-out page.
              </p>
              <br />
              <a
                style={{ color: "blue" }}
                href="http://tools.google.com/dlpage/gaoptout"
              >
                http://tools.google.com/dlpage/gaoptout
              </a> */}
          <p
            style={{
              color: "green",
              fontFamily: "cursive",
              fontSize: "1.5rem",
            }}
          >
            Public forums
          </p>
          <p style={{ lineHeight: "1.5" }}>
            Some parts of our site may enable you to participate in public
            services such as discussion boards, chats, and live events. Please
            use discretion when posting personal information about yourself when
            using these services. Be aware that when you disclose personal
            information at these sites, such as your name, member name, email
            addres the information may be collected and used by others to send
            unsolicited email. The services are open to the public, and what you
            post there can be seen by anyone and is not protected. We try to
            moderate the comments as best we can but we can't guarantee total
            control of the comments that you may receive while you participate
            in these services. Sometimes, you may find other people's comments
            to be offensive, harmful or inaccurate.
          </p>
          <p
            style={{
              color: "green",
              fontFamily: "cursive",
              fontSize: "1.5rem",
            }}
          >
            {" "}
            Our company's commitment to data security
          </p>
          <p style={{ lineHeight: "1.5" }}>
            We implement a variety of administrative, managerial, and technical
            security measures to help protect your personal information. Our
            Company has various internal control standards which relate
            specifically to the handling of personal information. These include
            certain controls to help safeguard the information we collect
            online. Our employees are trained to understand and comply with
            these controls and we communicate our Privacy Notice, practices and
            guidelines to our employees. However, while we strive to protect
            your personal information, you must also take steps to protect your
            information. We urge you to take every precaution to protect your
            personal information while you are on the Internet.
          </p>
          <br />
          <p style={{ lineHeight: "1.5" }}>
            We implements location-based personal services in a privacy policy,
            it's crucial to communicate clearly and transparently about how user
            data, especially location information, will be collected, used, and
            protected. We never share the location details to any third parties.
          </p>
          <br />
          <p style={{ lineHeight: "1.5" }}>
            We implements Mobile number for the personalized services through
            the app in a privacy policy, it's crucial to communicate clearly and
            transparently about how user data, will be collected, used, and
            protected. We never share the location details to any third parties.
          </p>
          <br />
          <p style={{ lineHeight: "1.5" }}>
            We implements Payments for the personalized services through the app
            in a privacy policy, it's crucial to communicate clearly and
            transparently about how user data, especially Payments information
            like cvv,card number,Holders Name,Phonepay,Patym,UPI will be
            collected, used, and protected. We never share the location details
            to any third parties.
          </p>
          <br />
          <p style={{ lineHeight: "1.5" }}>
            Services and Web sites we sponsor have security measures in place to
            protect the loss, misuse, and alteration of the information under
            our control. While we make every effort to ensure the integrity and
            security of our network and systems, we cannot guarantee that our
            security measures will prevent third-party "hackers" from illegally
            obtaining this information.
          </p>
          <br />
          <p
            style={{
              color: "green",
              fontFamily: "cursive",
              fontSize: "1.5rem",
            }}
          >
            Confidentiality of Information
          </p>
          <p style={{ lineHeight: "1.5" }}>
          Purplegene  provides its services in
            SaaS (Software as a Service) model, and will host data and
            information. It will not review, share, distribute, or reference any
            User or Expert data except as provided in the terms of use, or as
            may be required by law. Individual records of User data may be
            viewed or accessed only for the purpose of resolving a problem,
            support issues, or suspected violation of the terms of use, or as
            may be required by law. We would take all possible precautions for
            maintaining the confidentiality of data.
          </p>
          <br />
          <p
            style={{
              color: "green",
              fontFamily: "cursive",
              fontSize: "1.5rem",
            }}
          >
            Revisions to this policy
          </p>
          <p style={{ lineHeight: "1.5" }}>
            Our Company reserves the right to revise, amend, or modify this
            policy, our Terms Of Use, and our other policies and agreements at
            any time and in any manner, by updating this posting.
          </p>
          <br />
          <br />
          {/* <p>
               Note:<b> For any app deletion Please feel free to reach out to us at support@artifexarena.com
                in case you have any specific queries.</b>
              </p> */}
        </div>{" "}
      </div>
      <Footer/>
    </div>
  );
};
