// FAQ.js
import ARTIFEX from "../assets/ARTIFEX@2x.png";
import React, { useState, useEffect } from "react";
import axios from "axios";
import Config from "../Config.jsx";
import arrowUp from "../images/arrowUp.png";
import arrowdown from "../images/arrowDown.png";
import Footer from "./Footer.js";
const FAQ = () => {
  const [faqData, setFaqData] = useState([]);
  // const [isTrue, setIsTrue] = useState(true);


  useEffect(() => {
    // Fetch FAQ data when the component mounts
    const fetchData = async () => {
      try {
        const response = await axios.get(`${Config.base_url}/FaqQuestions`);
        setFaqData(response.data);
        console.log(response.data);
      } catch (error) {
        console.error("Error fetching FAQ data:", error);
      }
    };

    fetchData();
  }, []);

  const [expandedIndex, setExpandedIndex] = useState(null);

  const handleToggle = (index) => {
    setExpandedIndex((prevIndex) => (prevIndex === index ? null : index));
  
  };

  return (
    <div>
      <div id="navbar">
        <div
          id="navicon"
          style={{
            display: "flex",
            alignItems: "center",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              gap: "2%",
              width: "100%",
            }}
          >
            <a href="/" style={{ cursor: "pointer" }}>
              <div>
                <img
                  src={ARTIFEX}
                  alt="ARTIFEX"
                  width={"182px"}
                  height={"auto"}
                  className="artifex"
                />
              </div>
            </a>
          </div>
        </div>
      </div>
      <div style={{ margin: "5%" }}>
        {faqData.map((item, index) => (
          <div key={index} className="faq-item">
            <div className="question" onClick={() => handleToggle(index)}>
              <p>{item.question}</p>

              {expandedIndex === index  ? (
                <img src={arrowUp} width={30} height={30} alt="True Image" />
              ) : (
                <img src={arrowdown} width={30} height={30} alt="False Image" />
                
              )}
            </div>
            {expandedIndex === index && (
              <div className="answer">{item.answer}</div>
            )}
          </div>
        ))}
      </div>
      <Footer />
    </div>
  );
};

export default FAQ;
