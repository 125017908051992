import React, { useState, useEffect } from "react";
import icon_2 from "../assets/icon_new.png";
import ARTIFEX from "../assets/ARTIFEX@2x.png";
import Axios from "axios";
import { HashLink } from "react-router-hash-link";
import arrowforward from "../assets/arrow-forward.png";
import call from "../assets/call.png";
import mail from "../assets/mail.png";

// import img_1 from "../images/img_1.png";
// import img_2 from "../images/img_2.png";
// import img_3 from "../images/img_3.png";
// import img_4 from "../images/img_4.png";
// import img_15 from "../images/img_15.png";
import Config from "../Config.jsx";
import { Link } from "react-router-dom";
import Footer from "./Footer.js";
const Gole = () => {
  const [Data, setData] = useState([]);
  // const image = [img_1, img_2, img_3, img_4, img_15];
  const fetchData = async () => {
    try {
      await Axios.get(`${Config.base_url}/sustainabilityData`).then(
        (response) => {
          console.log(response.data, "____________");
          setData(response.data);
        }
      );
    } catch (error) {
      console.error(
        "There was a problem fetching the image list:",
        error.message
      );
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <div>
      <div id="navbar">
        <div
          id="navicon"
          style={{
            display: "flex",
            alignItems: "center",
            // width: "50%",
          }}
        >
          <div>
            <a href="/">
              <img
                src={ARTIFEX}
                alt="ARTIFEX"
                width={"182px"}
                height={"auto"}
                className="artifex"
              />
            </a>
          </div>
        </div>
        <p id="landing">SPRING '24 REGISTRATIONS ARE OPEN NOW</p>
        <div>
          <HashLink
           to="/Register#Now"
            style={{ textDecoration: "none", width: "50%" }}
          >
            <div id="downloadbutton">
              <div>
                <p>Register Now</p>
              </div>

              {/* <button onClick={handleOpen}>Download App</button> */}

              <div>
                <img src={arrowforward} />
              </div>
            </div>
          </HashLink>
        </div>
      </div>

      {Data.map((image, index) => (
        <div key={index} id={`section${index}`} style={{ paddingTop: "5%" }}>
          <img
            src={`${Config.imageUrl}/${image.image}`}
            alt={`Image ${index}`}
            style={{
              width: "90%",
              margin: "0 5%",
            }}
          />
        </div>
      ))}

      <div>
        <Footer />
      </div>
    </div>
  );
};
export default Gole;
